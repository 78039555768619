document.addEventListener("turbolinks:load", function() {
    var submit = document.getElementById("submit_order")
    $("#delivry_fr, #delivry_be, #delivry_lu, #delivry_ch").on("change", function(e) {
      if ($("#delivry_fr, #delivry_be, #delivry_lu, #delivry_ch").filter(":checked").length == 1) {
        submit.disabled = false;
        submit.style.opacity = "1";
      } else {
        submit.disabled = true;
        submit.style.opacity = "0.5";
      }
    });
  })