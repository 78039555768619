document.addEventListener("turbolinks:load", function() {
  var button = document.getElementById("button-warning-general-feeling");

  if (button) {
    button.onclick = function () {
      document.getElementById("warning-general-feeling").style.display = "none";
      document.getElementById("nose-irritation").style.display = "block";
    };
  }
});
