document.addEventListener("turbolinks:load", function () {
  var button_delete = document.getElementById("button-delete");
  if (button_delete) {
    button_delete.onclick = function () {
      document.getElementById("warning-modal").style.display = "block";
    };
  }

  var button_cancel = document.getElementById("cancel_delete");
  if (button_cancel) {
    button_cancel.onclick = function () {
      document.getElementById("warning-modal").style.display = "none";
    };
  }

});
